
.fc .fc-button-primary {
    border-color: #ffffff;
    background-color: #ff9800;
}

.fc .fc-button-primary:hover {
    border-color: #ff9800;
    background-color: #ff9800;
}

.fc .fc-button-primary:not(:disabled).fc-button-active, .fc .fc-button-primary:not(:disabled):active {
    background-color: #ff9800;
    border-color: #ff9800;
    color: black;
}

.fc .fc-button-primary:not(:disabled).fc-button-active:focus, .fc .fc-button-primary:not(:disabled):active:focus, .fc .fc-button:focus {
    box-shadow: none;
}
